<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Eğitim Listesi</h3>
      </div>
      <div class="card-body">
        <div class="ads-tabs">
          <div class="tabs-menus">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li class="">
                <a href="#tab1" class="active" data-bs-toggle="tab">Tümü</a>
              </li>
              <li><a href="#tab4" data-bs-toggle="tab">Taslak</a></li>
              <li><a href="#tab2" data-bs-toggle="tab">Aktif</a></li>
              <li><a href="#tab3" data-bs-toggle="tab">Onayda</a></li>
              <li><a href="#tab6" data-bs-toggle="tab">Reddedilen</a></li>
              <li><a href="#tab5" data-bs-toggle="tab">Sonlanmış</a></li>
            </ul>
          </div>
          <div class="tab-content">
            <div
              class="tab-pane active table-responsive border-top userprof-tab"
              id="tab1"
            >
              <table class="table table-bordered table-hover mb-0">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Katılım</th>
                    <th>Ücret</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <OwnEducationItem
                      :item="item"
                      :s="i"
                      :cont="true"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab2"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Katılım</th>
                    <th>Ücret</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <OwnEducationItem
                      :item="item"
                      :s="i"
                      :cont="item.status == 1"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab3"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Katılım</th>
                    <th>Ücret</th>
                    
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <OwnEducationItem
                      :item="item"
                      :s="i"
                      :cont="item.status == 2"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab4"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Katılım</th>
                    <th>Ücret</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <OwnEducationItem
                      :item="item"
                      :s="i"
                      :cont="item.status == 0"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab5"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Katılım</th>
                    <th>Ücret</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <OwnEducationItem
                      :item="item"
                      :s="i"
                      :cont="item.begin_date > Date('Y-m-d')"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab6"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Katılım</th>
                    <th>Ücret</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <OwnEducationItem
                      :item="item"
                      :s="i"
                      :cont="item.status == 3"
                    />
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import OwnEducationItem from "./Components/OwnEducationItem.vue";
export default {
  created() {
    document.title = "Eğitim Listesi";
    if (
      this.$store.state.user_type != "EXPERT" &&
      this.$store.state.trainer != 1
    ) {
      this.$router.push("/profile");
    }
    if (this.$route.params.page) {
      this.currentpage = this.$route.params.page;
    }
    let educationInfo = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
    };
    this.$store.dispatch("educationList", educationInfo).then((value) => {
      this.educationList = value.list;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.educationLoad = false;
    });
  },
  data() {
    return {
      educationList: [],
      currentpage: 1,
      perpage: 8,
      totalsize: 0,
      pagesize: 0,
      educationLoad: true,
    };
  },
  methods: {},
  components: { OwnEducationItem },
  mounted() {},
};
</script>