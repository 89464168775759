<template>
  <tr v-if="cont">
    <td>
      <div class="media mt-0 mb-0">
        <div class="card-aside-img">
          <router-link
            :to="item.status != 2 ? '/profile/education/update/' + item.id : ''"
          ></router-link>
          <img
            :src="'https://storage.terapivitrini.com/' + item.thumbnail"
            alt="img"
          />
        </div>
        <div class="media-body">
          <div class="card-item-desc ms-4 p-0 mt-2">
            <router-link
              :to="
                item.status != 2 ? '/profile/education/update/' + item.id : ''
              "
              class="text-dark"
              ><h4 class="font-weight-semibold">
                {{ item.title }}
              </h4></router-link
            >
            <router-link
              :to="
                item.status != 2 ? '/profile/education/update/' + item.id : ''
              "
              ><i class="fa fa-clock-o me-1"></i
              >{{ item.begin_date }} </router-link
            ><br />
            <router-link
              :to="
                item.status != 2 ? '/profile/education/update/' + item.id : ''
              "
              ><i class="fa fa-clock-o me-1"></i>{{ item.end_date }}
            </router-link>
          </div>
        </div>
      </div>
    </td>
    <td>
      <template v-if="item.category_name != null">{{
        item.category_name.experience_name
      }}</template>
    </td>
    <td class="font-weight-semibold fs-16">
      <span>{{ item.participant_size }}</span>/<span>{{ item.approved_participant }}</span>
    </td>
    <td class="font-weight-semibold fs-16">{{ item.price }} TL</td>
    <td>
      <span class="badge badge-secondary" v-if="item.status == 0">Taslak</span>
      <span class="badge badge-success" v-if="item.status == 1">Onaylı</span>
      <span class="badge badge-warning" v-if="item.status == 2"
        >Onay Bekliyor</span
      >
      <span class="badge badge-danger" v-if="item.status == 3">Reddedildi</span>
    </td>
    <td>
      <div class="">
        <router-link
          v-if="item.status != 2"
          :to="'/profile/education/update/' + item.id"
          class="btn btn-primary btn-sm text-white m-1"
          data-bs-toggle="tooltip"
          data-bs-original-title="Edit"
          title="Güncelle"
          ><i class="fa fa-pencil"></i
        ></router-link>
        <a
          v-if="item.status == 0 || item.status == 3"
          :to="''"
          class="btn btn-primary btn-sm text-white m-1"
          data-bs-toggle="tooltip"
          data-bs-original-title="Delete"
          @click="removeEducation(item)"
          title="Sil"
          ><i class="fa fa-trash-o"></i
        ></a>

        <a
          v-if="item.approvable == 1 && (item.status == 0 || item.status == 3)"
          class="btn btn-primary btn-sm text-white m-1"
          data-bs-toggle="tooltip"
          @click="approvedRequest(item.id)"
          title="Onaya Gönder"
          data-bs-original-title="Onaya Gönder"
          ><i class="fa fa-check"></i
        ></a>
        <router-link
          v-if="item.status == 1"
          :to="'/profile/education/participant/' + item.id"
          class="btn btn-primary btn-sm text-white m-1"
          data-bs-toggle="tooltip"
          title="Katılımcı Listesi"
          data-bs-original-title="Katılımcı Listesi"
          ><i class="fa fa-list"></i>
          <div
            style="
              width: 10px;
              height: 10px;
              background: red;
              border-radius: 10px;
              border: 2px solid #fff;
            "
            v-if="item.nonread > 0"
            ></div
          ></router-link
        >
        <router-link
          v-if="item.status == 1"
          :to="'/profile/education/' + item.id + '/comments'"
          class="btn btn-primary btn-sm text-white m-1"
          data-bs-toggle="tooltip"
          title="Yorumlar"
          data-bs-original-title="Yorumlar"
          ><i class="fa fa-comment"></i
        ></router-link>
        <router-link
          v-if="item.status == 1"
          :to="'/education/' + item.shortlink + '/' + item.id"
          class="btn btn-primary btn-sm text-white m-1"
          data-bs-toggle="tooltip"
          title="İncele"
          data-bs-original-title="İncele"
          ><i class="fa fa-eye"></i
        ></router-link>
      </div>
    </td>
  </tr>
</template>
<script>
import Swal from "sweetalert2";
export default {
  props: ["item", "cont", "s"],
  methods: {
    removeEducation(id) {
      Swal.fire({
        title: "Eğitimi silmek istediğinize emin misin?",
        text: "Bu işlem sonrası seçilen eğtim silinecek. Devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let info = { id: id.id };
          this.$store.dispatch("educationRemove", info).then((value) => {
            if (value.message == "SCCx001") {
              this.$vToastify.success("Eğitim Başarıyla Silindi", "Başarılı!");
              this.$parent.educationList.splice(this.s, 1);
            }
            if (value.message == "ERRxENRM") {
              this.$vToastify.warning("Böyle Bir Eğitim Bulunamadı", "Uyarı!");
            }
          });
        }
      });
    },
    approvedRequest(id) {
      Swal.fire({
        title: "Eğitimi onaya göndermek istediğinize emin misin?",
        text: "Bu işlem sonrası seçilen eğtim onaya gönderilecek. Onay sürecinde eğitimde değişiklik yapamazsınız, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let info = { id: id };
          this.$store
            .dispatch("educationApprovedRequest", info)
            .then((value) => {
              if (value.message == "SCCxSCC") {
                this.$vToastify.success("Eğitim onaya gönderildi", "Başarılı!");
              }
              if (value.message == "ERRxEARARVD") {
                this.$vToastify.warning(
                  "Eğitim zaten onaya gönderilmiş",
                  "Uyarı!"
                );
              }
            });
        }
      });
    },
  },
};
</script>